import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

export default function HTMLSitemap() {
  return (
    <Layout>
      <div className="mt-16 p-8 flex flex-col">
        <h1 className="place-self-center">HTML Sitemap</h1>
        <div className="flex flex-row flex-wrap justify-around">
          <ul>
            <li>
              <Link to="/" className="hover:text-red-500">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" className="hover:text-red-500">
                About Us
              </Link>
            </li>
            {/* <li>
              <Link to="/blog" className="hover:text-red-500">
                Blog
              </Link>
            </li> */}
            <li>
              <Link to="/contact" className="hover:text-red-500">
                Contact Us
              </Link>
            </li>
          </ul>
          <div className="lg:w-1/3">
            <StaticImage
              src="../images/pikes-peak-gog.jpg"
              className="w-full"
              alt="8x10 Storage Unit Fountain Colorado"
              quality={80}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
